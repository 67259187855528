import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/custom.css"
import "../assets/css/vendor/vendor.min.css"
import "../assets/css/plugins/plugins.min.css"


const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)




export default NotFoundPage
